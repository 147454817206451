import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "@environments/environment";

export const ManagementGuard: CanActivateFn = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot) => {

	let person = JSON.parse(localStorage.getItem('person'));
	if ((environment as any).hideEntireManagement == true && person?.persona?.tinc != true)
		return false;
	return true;
}